import React from "react"
//import { useStaticQuery, graphql } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image";
import {
  useIntl,
  Link,
  //FormattedMessage,
  //changeLocale,
} from "gatsby-plugin-react-intl";

const ComponentTextblock3 = () => {
  const intl = useIntl();
  return (
    <section className="sect sect_Product__footer p-0">
      <div className="container py-8 my-8 flex-row">
        <div className="flex-column-6">
          <h2 className="h3">
            {intl.locale === "en"
              ? `Do you want to know more about Cybernics Technology?`
              : `Wollen Sie mehr über die Cybernics Technologie erfahren?`}
          </h2>
          <p>
            {intl.locale === "en"
              ? `Cybernics is a coined term by Prof. Yoshiyuki Sankai, founder of Cyberdyne. Cybernics is an new cross-disciplinary academic field whose core disciplines are related to human, robotics and information systems.`
              : `Cybernics ist ein von Prof. Yoshiyuki Sankai, Gründer von Cyberdyne, geprägter Begriff. Cybernics ist ein neues interdisziplinäres akademisches Feld. Die Kernbereiche sind auf die Menschen, Roboter und Informationssysteme ausgelegt.`}
          </p>
          <div>
            <Link to="/company/cybernics/" className="link_button">
              {intl.locale === "en" ? `About Cybernics` : `Mehr zu Cybernics`}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComponentTextblock3;
