import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
//import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import ComponentTextblock3 from "../../components/component-textblock-3";

const ProductsIndex = ({ data, location }) => {
  const intl = useIntl();

  return (
    <Layout location={location}>
      <Seo
        title={intl.locale === "en" ? `` : ``}
        description={intl.locale === "en" ? `` : ``}
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/1w0VtVuhZsng5XiHy1xYJF/c9bfc1236811562182aac62919ac4919/E181_191.jpg?w=1200&h=630&fit=fill"
      />
      <section className="sect bg-white">
        <div className="container flex-row">
          <div className="flex-column-6 mt-9">
            <h1 className="h1">
              {intl.locale === "en"
                ? `Empowering Humankind`
                : `Menschen ermutigen`}
            </h1>
            <p className="message-label-bold txt txt-rot">
              {intl.locale === "en"
                ? `Our medical devices - Greater control over your body`
                : `Unsere medizinischen Produkte - Größere Kontrolle über Ihren Körper`}
            </p>
            <p>
              {intl.locale === "en"
                ? `Apply Cybernics technology to deliver revolutionary therapies and
  enhance quality of life for all people in need.`
                : `Wenden Sie die Cybernics Technologie an, um revolutionäre Therapien durchzuführen und die Lebensqualität von hilfsbedürftigen Menschen  zu verbessern`}
            </p>
          </div>
        </div>
      </section>

      <section className="sect position-relative overflow-visible">
        <StaticImage
          src="../../images/products/motion-principal-big-teaser.png"
          class=""
        />

        <div className="floating-box">
          <div className="p-8 bg-bls">
            <h2 className="h3">
              {intl.locale === "en"
                ? `Motion princible of HAL`
                : `Bewegungsprinzip des HALs`}
            </h2>
            <p>
              {intl.locale === "en"
                ? `Our products' innovative sensing and processing technology is
  able to pick up faint bioelectrical signals that are generated
  when a wearer attempts to move. HAL detects bioelectrical
  signals and provides the wearers with the appropriate power
  assistance in order to execute the desired movements.`
                : `Die innovative Wahrnehmungs- und Verarbeitungstechnologie unseres Produktes ermöglicht es selbst kleinste bioelektrische Signale, die erzeugt werden wenn der Träger versucht sich zu bewegen, aufzuzeichnen. Dem Träger wird dann eine angemessene Kraftunterstützung zur Vollendung der Bewegung zur Verfügung gestellt. `}
            </p>
            <div>
              <Link
                to="/products/medical-device/hal-motion-principal/"
                className="link-border link-border-white"
              >
                {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_Product__types p-0">
        <div className="p_base10_x">
          <div className="post_paneldouble bg-bge">
            <StaticImage
              src="../../images/products/index/lower-limb-type-teaser-square@2x.png"
              className="post_paneldouble__img"
            />
            <div className="post_paneldouble__txt">
              <div className="post_paneldouble__txt___inner">
                <p className="title">HAL Lower Limb Type</p>
                <p className="font_inter">
                  {intl.locale === "en"
                    ? `Think, Send, Read, Move—HAL is the World first neurologically
  controlled wearable Cyborg which puts your intentions into
  action. HAL improves locomotor activity in stroke, spinal cord
  injury and other neuromuscular disease patients.`
                    : `Denken, Senden, Lesen, Bewegen - HAL ist der weltweit erste neurologisch kontrollierte tragbare Cyborg, der Ihre Absichten in Aktionen umsetzt. HAL verbessert die Bewegungsfähigkeit nach Schlaganfällen, Rückenmarksverletzungen und anderen neuromuskulären Erkrankungen.`}
                </p>
                <div>
                  <Link
                    to="/products/medical-device/hal-limb/"
                    className="link-border"
                  >
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p_base_x">
          <div className="post_paneldouble bg-rot">
            <StaticImage
              src="../../images/products/index/hal-single-joint-teaser-square@2x.png"
              className="post_paneldouble__img"
            />
            <div className="post_paneldouble__txt">
              <div className="post_paneldouble__txt___inner">
                <p className="title">HAL Single Joint</p>
                <p className="font_inter">
                  {intl.locale === "en"
                    ? `HAL Single Joint is a portable and lightweight unit which
  enables intensive and repepetive training of specific joints
  such as elbow, knee, and ankle. The compact design makes it
  possible to be used in a wide range of environments including
  in the ICU or at the bedside in a hospital ward.`
                    : `HAL Single Joint ist ein portables und leichtes Gerät, das ein intensives und wiederholtes Training eines speziellen Gelenkes, z.B. Ellbogen, Knie und Sprunggelenk, ermöglicht. Das kompakte Design erlaubt die Anwendung unter verschiedenen Rahmenbedingungen, einschließlich Intensivstationen und anderen Krankenkausstationen.`}
                </p>
                <div>
                  <Link
                    to="/products/medical-device/hal-joint/"
                    className="link-border link-border-white"
                  >
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_Product__types p-0">
        <div className="container py-8 my-8">
          <h2 className="message-lg">
            {intl.locale === "en"
              ? `Cybernics technology was developed as human-assistive and
  enhancement technology and is expected to be used in a wide range of
  fields covering medicine, care, living, and labor-intensive jobs`
              : `Die Cybernics Technologie wurde als menschenunterstützende und verbessernde Technologie entwickelt und kann in vielen Bereichen, wie Medizin, Pflege, Alltag und körperlich anstrengen Berufen eingesetzt werden. `}
          </h2>
          <div className="flex-row">
            <div className="flex-column-6">
              <p className="message-label-bold txt txt-rot">
                {intl.locale === "en"
                  ? `Our non-medical devices - Prolong a high-quality of life`
                  : `Unsere nicht-medizinischen Geräte - Lebensqualität erhalten`}
              </p>
              <p>
                {intl.locale === "en"
                  ? `A wide range of non-medical products covers multiple
  applications from diagnosis, prevention, communication, and
  cleaning and disinfection`
                  : `Eine große Bandbreite an nichtmedizinischen Produkten umfasst Anwendungsbereich der Diagnostik, Prävention, Kommunikation, Reinigung und Desinfektion`}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sect position-relative overflow-visible">
        <StaticImage
          src="../../images/products/index/lumbar-type-big-teaser2x.png"
          className=""
        />

        <div className="floating-box top-right">
          <div className="p-8 bg-bls">
            <h2 className="h3">HAL Lumbar Type</h2>
            <p>
              {intl.locale === "en"
                ? `Improving a quality of life by ensuring your well-being, reducing fatigue in the work environment, regaining your strength and mobility, and expanding your capability.`
                : `Verbesserung der Lebensqualität durch Sicherstellung Ihres Wohlbefindens, Reduzierung der Ermüdung im Arbeitsumfeld, Wiedererlangen Ihrer Kraft und Mobilität, sowie Ausbau Ihrer Leistungsfähigkeit.`}
            </p>
            <div>
              <Link
                to="/products/non-medical-device/"
                className="link-border link-border-white"
              >
                {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_Product__cyin p-0">
        <div className="p_base10_x">
          <div className="post_paneldouble bg-rot">
            <StaticImage
              src="../../images/products/index/cyin-teaser-square@2x.png"
              className="post_paneldouble__img"
            />
            <div className="post_paneldouble__txt">
              <div className="post_paneldouble__txt___inner">
                <p className="title">CYIN</p>
                <p className="font_inter">
                  {intl.locale === "en"
                    ? `Ingenious interfaces for people including those suffer from
  progressive neurodegenerative disease. CYIN utilizes
  biological information to help communicate with ease, despite
  all their limitations.`
                    : `Geniale Schnittstelle für Menschen, einschließlich derer die an progressiven neurodegenerativen Erkrankungen leiden. CYIN nutzt biologische Informationen um die Kommunikation, unabhängig all Ihrer Einschränkungen, zu vereinfachen.`}
                </p>
                <div>
                  <Link
                    to="/products/communication/"
                    className="link-border link-border-white"
                  >
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p_base_x">
          <div className="post_paneldouble bg-bge">
            <StaticImage
              src="../../images/products/index/acoustic-x-teaser-square@2x.png"
              className="post_paneldouble__img"
            />
            <div className="post_paneldouble__txt">
              <div className="post_paneldouble__txt___inner">
                <p className="title">Acoustic X</p>
                <p className="font_inter">
                  {intl.locale === "en"
                    ? `Acoustic X is photoacoustic imaging technology that helps
  detect melanin, lipid, collages, water, and contrast agents
  specifically designed to locate various biomarkers.`
                    : `Acoustic X ist eine photoakustische Bildgebungstechnologie, die Melanin, Fette, Kollagen und Kontrastmittel zur Lokalisation bestimmter Biomarker, erkennt.`}
                </p>
                <div>
                  <Link to="/products/imaging/" className="link-border">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sect position-relative overflow-visible">
        <StaticImage
          src="../../images/products/index/cleaning-robot-big-teaser2x.png"
          class=""
        />

        <div className="floating-box left-bottom">
          <div className="p-8 bg-bls">
            <h2 className="h3">
              {intl.locale === "en" ? `Cleaning robot` : `Reinigungsroboter`}
            </h2>
            <p>
              {intl.locale === "en"
                ? `Keeping large facilities such as airport, shopping malls,
  hotels, and conference centers clean has never been easier.
  Find out how our cleaning and disinfection robot keeps your
  space tidy without the need for human operation.`
                : `Es war nie einfacher große Gebäude wie Flughäfen, Einkaufszentren, Hotels und Konferenzzentren sauber zu halten. Finden Sie heraus wie unser Reinigungsroboter Ihre Räume ohne menschliche Unterstützung sauber hält.`}
            </p>
            <div>
              <Link
                to="/products/cleaning-robot/"
                className="link-border link-border-white"
              >
                {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <ComponentTextblock3 />
    </Layout>
  );
};

export default ProductsIndex;
